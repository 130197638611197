<template>
  <details
    ref="details"
    :open="_open"
    :name="name"
    class="collapsable"
    data-testid="details"
    @toggle="toggleDetails"
  >
    <summary
      class="collapsable__label"
      data-testid="summary"
    >
      <slot
        name="label"
        :label="label"
        :open="_open"
      >
        <slot
          name="icon_before"
          :open="_open"
        />

        <span>{{ label }}</span>

        <slot
          name="icon_after"
          :open="_open"
        >
          <IconArrow
            class="collapsable__icon icon icon--arrow"
            :class="{ 'icon--arrow-bottom': !_open, 'icon--arrow-top': _open }"
          />
        </slot>
      </slot>
    </summary>
    <div
      class="collapsable__content"
      data-testid="content"
    >
      <slot>Details</slot>
    </div>
  </details>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  label?: string
  name?: string
  open?: boolean
}>(), {
  label: 'Summary',
  name: '',
  open: false,
})

const { label, open } = toRefs(props)

const _open = ref(open.value)

const details = ref<HTMLDetailsElement | null>(null)
const toggleDetails = () => {
  _open.value = !!details.value?.open
}

watch(() => open, () => {
  _open.value = open.value
}, {
  immediate: true,
})

onMounted(() => {
  _open.value = open.value
})
</script>

<style src="~layers/app/components/Ui/Collapsable.css" />
